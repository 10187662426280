
import { defineComponent } from 'vue';
import Report from '@/components/Report.vue';

export default defineComponent({
    name: 'ReportView',
    components: {
        Report
    }
});
